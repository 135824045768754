<template>
 <div class="body-text">
    <h3>拉萨维多利亚整形美容医院来院地址：</h3>
附近的公交站:
东郊客运站站、邮车总站站、邮车总站、老藏大、臧大、藏大站、邮车总站、市政公司站、雅鲁藏布酒店站、日光宾馆东门站、日光宾馆站、市供电所站、市农牧局站、阳城广场站、市供电所站、市畜牧局站。
拉萨维多利亚整形美容医院附近的公交车:
10路、9路、27路、12路、17路、8路、4路、6路、11路、3路、5路、15路等。
 咨询预约电话<a href="tel:0891-6405555"
      >0891-6405555</a
    >, 在线咨询链接：<a
      href="https://kf7.kuaishang.cn/bs/im.htm?cas=57051___573251&fi=67633"
      >美丽咨询</a
    >。
	你也可以关注微信公众号
  </div>
<TenMap></TenMap>
</template>

<script>
import  TenMap  from '@/components/Map.vue'
export default {
  name: "Map",
  components: {TenMap},
};
</script>

<style>
</style>
<template>
<div class="common-layout"> 
<el-container>
  <el-header height="400">
    
  <moblie-header></moblie-header>
    
    </el-header>
  <el-main> 
        
    
    <router-view/>
    
    
    
    </el-main>
    <moblie-footer></moblie-footer>
</el-container>

 </div>
</template>
<script>
import MoblieHeader from './components/MoblieHeader.vue'
import MoblieFooter from '@/components/MoblieFooter'
export default {
  components: { MoblieHeader,MoblieFooter },
  
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<template>
  <div class="footer">
    <div class="block">
      <el-avatar :size="50" :src="circleUrl"></el-avatar>
    </div>
    <P
      >CopyRight© 2022 | 拉萨维多利亚整形美容医院有限公司 | lswdly All
      Rights Reserved  ICP备案：<el-link
        :underline="false"
        href="https://beian.miit.gov.cn/"
        >藏ICP备16000129号-2</el-link>,
        医疗广审编号：藏医广【2022】第7-27-18号
    </P>
  </div>
</template>

<script>
export default {
  name: "MoblieFooter",
  data() {
    return {
      circleUrl: require("@/assets/images/foot_logo.png"),
    };
  },
};
</script>

<style>
</style>
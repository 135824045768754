<template>
  <div class="logo">
    <el-image :src="logo" width="399px" height="69px"></el-image>
  </div>
  <el-menu
    :default-active="activeIndex"
    select="index"
    class="el-menu-demo"
    mode="horizontal"
    @select="handleSelect"
    background-color="#5b4dac"
    active-text-color="#ffffff"
    text-color="#ffffff"
  >
    <el-menu-item index="1">
      
           <router-link to="/">首页</router-link>
    </el-menu-item>
    <el-menu-item index="3">
      
       <router-link to="/body">简介</router-link>
    </el-menu-item>
     <el-menu-item index="4">
      
       <router-link to="/map">医院地址</router-link>
    </el-menu-item>

    <el-menu-item index="5">
      <el-link  type="primary"  :underline="false"   href="tel:0891-6405555">咨询电话</el-link>
    </el-menu-item>
    <el-menu-item index="6">
      <el-link  type="primary"  :underline="false"  href="https://kf7.kuaishang.cn/bs/im.htm?cas=57051___573251&fi=67633">美丽咨询</el-link
      >
    </el-menu-item>
  </el-menu>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>|
    
    <router-link to="/map">地图</router-link>
  </nav> -->
</template>

<script>
export default {
  name: 'MobileHeader',
  data() {
    return {
  logo: require("@/assets/images/logo.png"),
      		activeIndex: '1',
    };
  },
};
</script>

<style>
.logo {
			width: 100%;
			display: flex;
			justify-content: center;
			margin-bottom: 10px;
		}

header.el-header {
    padding: 0px;
}
		.logo img {
			width: 248px;
		}
a{ list-style-type: none; text-decoration-line: none;}
</style>
<template>

   <moblie-main></moblie-main>
</template>

<script>
// @ is an alias to /src
import MoblieMain from '@/components/MoblieMain'

export default {
  name: 'HomeView',
  components: {
 MoblieMain 
  }
}
</script>


 <template>
	<div id="container"></div>
</template>


<script>
import TMap from "TMap";
export default {
  name:'TenMap',
	data() {
		return {
			nowLat:29.647503, // 纬度
			nowLng: 91.149683, // 经度
			map: null, // 地图对象
		}
	},
	created() {
		this.mapInit(this.nowLng, this.nowLat, 17)
	},
	methods: {
		/**
     	* 根据传入的值渲染地图及传出经纬度和地名
     	* @param lng 经度
     	* @param lat 纬度
     	* @param zoom 地图缩放级别
     	*/
		mapInit(lng, lat, zoom) {
			let _this = this;
			// 在页面渲染完成后进行地图的实例化
			this.$nextTick(() => {
				_this.map = new TMap.Map(document.getElementById("container"), {
          		// 地图的中心地理坐标。
          		center: new TMap.LatLng(lat, lng),
          		zoom: 17
        	});
			})
		},
	}
}
	
</script>
<style>
#container{
	width: 100%;
	height: 300px;
}
</style>
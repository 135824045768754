import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


const app = createApp(App)


app.use(router)
// 新增代码：注册全部组件
app.use(ElementPlus)


app.mount('#app')


<template>
  <moblie-body>
    
  </moblie-body>
</template>

<script>
import MoblieBody from '@/components/MoblieBody'
export default {
  name:'YiyBody',
  components:{
           MoblieBody
  }
}
</script>

<style>

</style>